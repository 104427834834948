import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthContext } from './AuthContext';
import { useCookies } from 'react-cookie';

const ProtectedRoute = () => {
  const { userToken, login } = useAuthContext();
  const location = useLocation();
  const [cookies] = useCookies(['userToken', 'refreshToken', 'userTokenExpiration']);

  const userTokenExpiration = new Date(cookies.userTokenExpiration);
  const now = new Date();
  

   useEffect(() => {
    if (cookies.userToken && cookies.refreshToken) {
      login(cookies.userToken, cookies.refreshToken);
    }
   }, []);

  if ((userToken == null || userToken === "" || now > userTokenExpiration) && !(cookies.userToken)) {    
    return (
      <Navigate
        to={'/login'}
        replace
        state={{
          redirectTo: location
        }}
      />
    );
  }

  return <Outlet />;
};

export default ProtectedRoute;
